import gql from 'graphql-tag';

const addOrganizationMutation = gql`
  mutation registerOrganization(
    $organizationName: String!
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    registerOrganization(
      input: {
        organizationName: $organizationName
        firstName: $firstName
        lastName: $lastName
        email: $email
      }
    ) {
      organization {
        id
        organizationName
        contactId
      }
    }
  }
`;

const escalateOrgAdmin = gql`
  mutation makeOrgAdmin($tuserId: UUID!) {
    makeOrgAdmin(input: { tuserId: $tuserId }) {
      changed: boolean
    }
  }
`;

const deescalateOrgAdmin = gql`
  mutation dropOrgAdmin($tuserId: UUID!) {
    dropOrgAdmin(input: { tuserId: $tuserId }) {
      changed: boolean
    }
  }
`;

const addUserMutation = gql`
  mutation addUser(
    $organizationId: UUID!
    $firstName: String!
    $lastName: String!
    $email: String!
  ) {
    addUser(
      input: {
        organizationId: $organizationId
        firstName: $firstName
        lastName: $lastName
        temail: $email
      }
    ) {
      user {
        id
        firstName
        lastName
        organizationId
        createdAt
      }
    }
  }
`;

const disableOrgMutation = gql`
  mutation disableOrganization($torg: UUID!) {
    disableOrganization(input: { torg: $torg }) {
      disabled: boolean
    }
  }
`;

const enableOrgMutation = gql`
  mutation enableOrganization($torg: UUID!) {
    enableOrganization(input: { torg: $torg }) {
      enabled: boolean
    }
  }
`;

const disableUserMutation = gql`
  mutation disableUser($tuser: UUID!) {
    disableUser(input: { tuser: $tuser }) {
      disabled: boolean
    }
  }
`;

const enableUserMutation = gql`
  mutation enableUser($tuser: UUID!) {
    enableUser(input: { tuser: $tuser }) {
      enabled: boolean
    }
  }
`;

const newPasswordMutation = gql`
  mutation newPassword($requestUser: UUID!) {
    newPassword(input: { requestUser: $requestUser }) {
      passed: boolean
    }
  }
`;

//the mutations below are for admin overrides and manual setting of passwords:
const addOrgManuallyMutation = gql`
  mutation addOrgManually(
    $organizationName: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    addOrgManually(
      input: {
        organizationName: $organizationName
        firstName: $firstName
        lastName: $lastName
        temail: $email
        pword: $password
      }
    ) {
      newOrg {
        id
        organizationName
        email
      }
    }
  }
`;

const addUserManuallyMutation = gql`
  mutation addUserManually(
    $organizationId: UUID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
  ) {
    addUserManually(
      input: {
        organizationId: $organizationId
        firstName: $firstName
        lastName: $lastName
        temail: $email
        pword: $password
      }
    ) {
      newUser {
        id
        firstName
        lastName
        organizationId
        createdAt
      }
    }
  }
`;

const adminChangePasswordMutation = gql`
  mutation adminChangePassword($userId: UUID!, $password: String!) {
    adminChangePassword(input: { id: $userId, pword: $password }) {
      clientMutationId
      boolean
    }
  }
`;

export {
  newPasswordMutation,
  disableOrgMutation,
  enableOrgMutation,
  disableUserMutation,
  enableUserMutation,
  addOrganizationMutation,
  addUserMutation,
  escalateOrgAdmin,
  deescalateOrgAdmin,
  addOrgManuallyMutation,
  addUserManuallyMutation,
  adminChangePasswordMutation
};
