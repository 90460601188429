import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import {
  enableUserMutation,
  disableUserMutation
} from './graphql/mutations/secMutations';

class EnableDisableUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgQuery: null,
      isActive: props.currentState
    };
  }

  componentDidUpdate(prevProps) {
    const { currentState } = this.props;
    if (prevProps.currentState !== currentState) {
      this.setState({
        isActive: currentState
      });
    }
  }

  sent = data => {
    this.setState({ isActive: !this.state.isActive });
  };

  render() {
    const { id, query } = this.props;
    const { isActive } = this.state;
    return isActive === true ? (
      <Mutation
        mutation={disableUserMutation}
        variables={{ tuser: id }}
        refetchQueries={() => [{ query: query }]}
        onCompleted={data => this.sent(data)}
      >
        {disableUser => (
          <Dropdown.Item
            icon="exclamation"
            text="Disable User"
            onClick={disableUser}
          />
        )}
      </Mutation>
    ) : (
      <Mutation
        mutation={enableUserMutation}
        variables={{ tuser: id }}
        refetchQueries={() => [{ query: query }]}
        onCompleted={data => this.sent(data)}
      >
        {enableUser => (
          <Dropdown.Item
            icon="exclamation"
            text="Enable User"
            onClick={enableUser}
          />
        )}
      </Mutation>
    );
  }
}
export default EnableDisableUser;
