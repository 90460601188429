import React, { Component } from 'react';
import { Modal, Dropdown } from 'semantic-ui-react';
import AddUserForm from './AddUserForm';

class AddUserButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addUser: false
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      addUser: !prevState.addUser
    }));
  };

  closeModalCallback = () => {
    this.setState({
      addUser: false
    });
  };

  render() {
    const { orgId, orgname, query } = this.props;
    const { addUser } = this.state;

    return (
      <React.Fragment>
        <Dropdown.Item icon="add user" text="Add User" onClick={this.toggle} />
        <Modal open={addUser} onClose={this.toggle}>
          <Modal.Header>Add User for {orgname}</Modal.Header>
          <Modal.Content>
            <AddUserForm
              orgId={orgId}
              query={query}
              callback={this.closeModalCallback}
            />
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  }
}
export default AddUserButton;
