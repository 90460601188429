import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import {
//   Container,
//   Card,
//   CardBody,
//   CardHeader,
//   Form,
//   FormGroup,
//   Row,
//   Col,
//   Label,
//   Input,
//   Button,
//   CardLink,
//   Alert
// } from 'reactstrap';
import {
  Grid,
  Message,
  Card,
  Form,
  Button,
  Image,
  Header,
} from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { loginMutation } from './graphql/mutations/loginMutation';
import AuthService from '../Auth/AuthService';
//import { getNegativePatternsAsPositive } from 'fast-glob/out/managers/tasks';

const Auth = new AuthService();

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      alert: false,
      color: null,
      message: '',
    };
  }

  login = (data) => {
    if (!data.authenticate.jwt) {
      this.setState({
        alert: true,
        color: 'red',
        message: 'Incorrect Username or Password',
      });
    } else {
      Auth.setToken(data.authenticate.jwt);
      this.props.history.push('/');
    }
  };

  render() {
    const { email, password } = this.state;
    return (
      <Grid container relaxed width={16}>
        <Grid.Row stretched centered>
          <Grid.Column width={6} stretched verticalAlign={'middle'}>
            <Image
              centered
              size={'large'}
              src="https://sps-client-logos.s3.amazonaws.com/SPS-logo_tagline_transparent.png"
            />
            <Header as="h2" color="grey">
              Welcome to LandPoint by Single Point Solutions
            </Header>
            <p className="subtitle">Please login to proceed.</p>
            <Card fluid>
              <Card.Content textAlign="left">
                <Form>
                  {this.state.alert && (
                    <Message
                      visible={this.state.alert}
                      color={this.state.color}
                      content={this.state.message}
                    />
                  )}
                  <Form.Group>
                    <Form.Input
                      name="email"
                      value={email}
                      type="email"
                      width={16}
                      icon="user"
                      iconPosition="left"
                      placeholder="E-mail address"
                      onChange={(e) => this.setState({ email: e.target.value })}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      name="password"
                      value={password}
                      type="password"
                      width={16}
                      icon="lock"
                      iconPosition="left"
                      placeholder="Password"
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Mutation
                      mutation={loginMutation}
                      variables={this.state}
                      onCompleted={(data) => this.login(data)}
                    >
                      {(doLogin) => (
                        <Button color="grey" fluid onClick={doLogin}>
                          Login
                        </Button>
                      )}
                    </Mutation>
                  </Form.Group>
                </Form>
              </Card.Content>
            </Card>
            <Link to="/p/f">Forgot Password?</Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
