import React, { Component } from 'react';
import { Table, Dropdown, Label, Button, Popup } from 'semantic-ui-react';
import NewPassword from './NewPassword';
import EscalateOrgAdmin from './EscalateOrgAdmin';
import EnableDisableUser from './EnableDisableUser';

class Users extends Component {
  constructor(props) {
    super(props);

    const role = props.role;
    let showPassReset, showAccountAction, showPromoteUser, showDisabledUsers;

    switch (role) {
      case 'app_authenticated':
        showPassReset = true;
        showAccountAction = false;
        showPromoteUser = false;
        showDisabledUsers = false;
        break;
      case 'app_administrator':
        showPassReset = true;
        showAccountAction = true;
        showPromoteUser = true;
        showDisabledUsers = true;
        break;
      case 'app_org_admin':
        showPassReset = true;
        showAccountAction = true;
        showPromoteUser = true;
        showDisabledUsers = true;
        break;
      default:
        showPassReset = false;
        showAccountAction = false;
        showPromoteUser = false;
        showDisabledUsers = false;
        break;
    }

    this.state = {
      showPassReset: showPassReset,
      showAccountAction: showAccountAction,
      showPromoteUser: showPromoteUser,
      showDisabledUsers: showDisabledUsers,
      toggleDisabledUsers: true,
    };
  }

  toggleView = () =>
    this.setState((prevstate) => ({
      toggleDisabledUsers: !prevstate.toggleDisabledUsers,
    }));

  render() {
    const { users, query } = this.props;
    const {
      showPassReset,
      showPromoteUser,
      showAccountAction,
      showDisabledUsers,
      toggleDisabledUsers,
    } = this.state;

    console.log(showDisabledUsers, toggleDisabledUsers);

    const tableRows = users.map((user) => {
      let admin;
      let userDisabled = false;

      if (user && user.isOrgAdmin) {
        admin = (
          <Label color="teal" size="mini" horizontal>
            ADMIN
          </Label>
        );
      }

      if (user && !user.isActive) {
        userDisabled = true;
      }

      if (!toggleDisabledUsers && userDisabled) {
        return null;
      }

      return (
        <Table.Row key={user.email}>
          <Table.Cell disabled={userDisabled}>
            {user.organizationName}
          </Table.Cell>
          <Table.Cell disabled={userDisabled}>{user.email}</Table.Cell>
          <Table.Cell disabled={userDisabled}>
            {user.firstName + ' ' + user.lastName} {admin}
          </Table.Cell>

          <Table.Cell>
            {(showPassReset || showPromoteUser || showAccountAction) && (
              <Dropdown item icon="ellipsis horizontal">
                <Dropdown.Menu>
                  {showPassReset && <NewPassword id={user.id} />}
                  {showPromoteUser && (
                    <EscalateOrgAdmin
                      currentState={user.isOrgAdmin}
                      id={user.id}
                      query={query}
                    />
                  )}
                  {showAccountAction && (
                    <EnableDisableUser
                      id={user.id}
                      currentState={user.isActive}
                      query={query}
                    />
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">USERS</Table.HeaderCell>
            <Table.HeaderCell colSpan="2">
              {showDisabledUsers && (
                <Popup
                  content="Show/Hide Disabled Users"
                  size="tiny"
                  inverted
                  position="left center"
                  trigger={
                    <Button
                      size="small"
                      toggle
                      color="teal"
                      icon="user x"
                      onClick={this.toggleView}
                    />
                  }
                />
              )}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell>Org. Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>User Name</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{tableRows}</Table.Body>
      </Table>
    );
  }
}
export default Users;
