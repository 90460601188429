import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Login from './Components/Open/Login';
import Home from './Components/Secured/Home';
// import Verify from './Open/Verify';
import Reset from './Components/Open/Reset';
import FPassword from './Components/Open/FPassword';
import './Components/Styles/App.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route path="/p/r/" component={Reset} />
          {/* <Route path="/v/a/" component={Verify} /> */}
          <Route path="/p/f/" component={FPassword} />
        </Switch>
      </div>
    );
  }
}

export default App;
