import React, { Component } from 'react';
import { Button, Modal, Popup, Icon } from 'semantic-ui-react';
import AddOrganizationForm from './AddOrganizationForm';

class AddOrganizationButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addOrg: false
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      addOrg: !prevState.addOrg
    }));
  };

  closeModalCallback = () => {
    this.setState({
      addOrg: false
    });
  };

  render() {
    const { query } = this.props;
    const { addOrg } = this.state;
    return (
      <React.Fragment>
        <Popup
          size="tiny"
          inverted
          content="Add New Organization"
          position="left center"
          trigger={
            <Button
              icon
              color="black"
              size="small"
              float="right"
              onClick={this.toggle}
            >
              <Icon name="add" />
            </Button>
          }
        />

        <Modal open={addOrg} onClose={this.toggle}>
          <Modal.Header>New Organization</Modal.Header>
          <Modal.Content>
            <AddOrganizationForm
              query={query}
              callback={this.closeModalCallback}
            />
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  }
}
export default AddOrganizationButton;
