import gql from 'graphql-tag';

const getOrganizations = gql`
  {
    orgs: allOrganizations {
      org: nodes {
        nodeId
        id
        organizationName
      }
    }
  }
`;

const getAdminOrganizations = gql`
  {
    orgs: allOrgAdministrators {
      org: nodes {
        id
        organizationName
        email
        activeUsers
        maxActiveUsers
        isActive
      }
    }
  }
`;
const getAdminUsers = gql`
  {
    users: allAdminUsers {
      user: nodes {
        id
        firstName
        lastName
        email
        organizationId
        isOrgAdmin
        isActive
        isVerified
        createdAt
        lastRequest
        requestExpires
      }
    }
  }
`;

const getOrgAdminUsers = gql`
  {
    users: allOrgAdminUsers {
      user: nodes {
        id
        firstName
        lastName
        email
        organizationId
        createdAt
        isOrgAdmin
        isActive
        isVerified
        lastRequest
        requestExpires
      }
    }
  }
`;

const getUsers = gql`
  {
    users: allUsers {
      user: nodes {
        id
        firstName
        lastName
      }
    }
  }
`;

const administratorsQuery = gql`
  {
    users: allAdminUsers(orderBy: [ORGANIZATION_NAME_ASC, FIRST_NAME_ASC]) {
      user: nodes {
        id
        firstName
        lastName
        email
        organizationId
        organizationName
        createdAt
        isOrgAdmin
        isActive
        isVerified
        lastRequest
        requestExpires
      }
      totalCount
    }
    orgs: allOrgAdministrators(orderBy: ORGANIZATION_NAME_ASC) {
      org: nodes {
        id
        organizationName
        email
        activeUsers
        maxActiveUsers
        isActive
      }
      totalCount
    }
  }
`;

const orgAdminsQuery = gql`
  {
    users: allOrgAdminUsers(orderBy: IS_ORG_ADMIN_DESC) {
      user: nodes {
        id
        organizationId
        firstName
        lastName
        email
        createdAt
        isOrgAdmin
        isVerified
        lastRequest
        requestExpires
      }
      totalCount
    }
    orgs: allOrganizations {
      org: nodes {
        id
        organizationName
      }
      totalCount
    }
  }
`;

const authenticatedUsersQuery = gql`
  {
    users: allUsers(orderBy: FIRST_NAME_ASC) {
      user: nodes {
        id
        firstName
        lastName
        organizationId
        createdAt
      }
    }
    orgs: allOrganizations {
      org: nodes {
        organizationName
      }
    }
  }
`;

export {
  getOrganizations,
  getAdminOrganizations,
  getUsers,
  getAdminUsers,
  getOrgAdminUsers,
  administratorsQuery,
  orgAdminsQuery,
  authenticatedUsersQuery,
};
