import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Mutation } from 'react-apollo';
import {
  Grid,
  Card,
  Message,
  Form,
  Button,
  Image,
  Header,
} from 'semantic-ui-react';
import { changePasswordMutation } from './graphql/mutations/recoverPassword';

export default class Reset extends Component {
  constructor(props) {
    super();
    this.state = {
      tpuuid: null,
      tquuid: null,
      newPassword: null,
      confirm_password: null,
      invalid: false,
      alert: false,
      color: null,
      message: '',
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (this.state[e.target.name] !== this.state.newPassword) {
      this.setState({ invalid: true });
    } else {
      this.setState({ invalid: false });
    }
  };

  recovery = (data) => {
    if (!data.changePassword.changed) {
      this.setState({
        alert: true,
        color: 'negative',
        message: 'Invalid or Expired Request',
      });
    } else {
      this.setState({
        alert: true,
        color: 'positive',
        message: 'Password Successfully Changed',
      });
      setTimeout(() => {
        window.location.replace('https://landpoint.spsgis.com/login');
      }, 5000);
    }
  };

  componentWillMount() {
    let url = this.props.location.search;
    this.setState({
      tpuuid: queryString.parse(url).p,
      tquuid: queryString.parse(url).q,
    });
  }

  componentDidMount() {
    if (!this.state.tpuuid && !this.state.tquuid) {
      this.setState({
        alert: true,
        color: 'warning',
        message: "You Shouldn't Be Here",
      });
      setTimeout(() => {
        this.props.history.push('/login');
      }, 3000);
    }
  }

  render() {
    const { invalid } = this.state;
    return (
      <Grid container relaxed width={16}>
        <Grid.Row stretched centered>
          <Grid.Column width={6} stretched verticalAlign={'middle'}>
            <Image
              centered
              small
              src="https://sps-client-logos.s3.amazonaws.com/SPS-logo_tagline_transparent.png"
            />
            <Header as="h2" color="grey">
              Welcome to LandPoint by Single Point Solutions
            </Header>
            <p className="subtitle">
              Please enter and confirm your new password below.
            </p>
            <Card centered fluid>
              <Card.Content>
                <Form>
                  {this.state.alert && (
                    <Message
                      visible={this.state.alert}
                      color={this.state.color}
                      content={this.state.message}
                    />
                  )}
                  <Form.Group>
                    <Form.Input
                      type="password"
                      name="newPassword"
                      icon="lock"
                      iconPosition="left"
                      width={16}
                      placeholder="New Password"
                      onChange={this.handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      error={invalid}
                      type="password"
                      name="confirm_password"
                      icon="unlock"
                      width={16}
                      iconPosition="left"
                      placeholder="Repeat New password"
                      onChange={this.handleChange}
                      onKeyUp={this.handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Mutation
                      mutation={changePasswordMutation}
                      variables={this.state}
                      onCompleted={(data) => this.recovery(data)}
                    >
                      {(newPassword) => (
                        <Button color="grey" fluid onClick={newPassword}>
                          Submit
                        </Button>
                      )}
                    </Mutation>
                  </Form.Group>
                </Form>
              </Card.Content>
            </Card>
            <Link to="/login">Go To Login</Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
