import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import {
  addOrganizationMutation,
  addOrgManuallyMutation
} from './graphql/mutations/secMutations';
import { Button, Form, Message, Radio } from 'semantic-ui-react';

class AddOrganizationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationName: '',
      firstName: '',
      lastName: '',
      email: '',
      alert: true,
      color: 'green',
      message: '',
      adminSetsPassword: false,
      password: ''
    };
  }

  handleInputChange = (e, { name, value }) => {
    this.setState({
      [name]: value
    });
  };

  handleRadioChange = (e, { value }) => {
    if (value === 'true') {
      this.setState({
        adminSetsPassword: true
      });
    } else {
      this.setState({
        adminSetsPassword: false
      });
    }
  };

  reset = () => {
    this.setState({
      organizationName: '',
      firstName: '',
      lastName: '',
      email: '',
      alert: true,
      color: 'green',
      message: '',
      adminSetsPassword: false,
      password: ''
    });
  };

  sent = data => {
    const { callback } = this.props;
    const { adminSetsPassword } = this.state;
    let org;

    if (adminSetsPassword) {
      org = data.addOrgManually.newOrg;
    } else {
      org = data.registerOrganization.organization;
    }

    if (!org) {
      this.setState(
        {
          alert: false,
          color: 'red',
          message: 'Organization Could Not Be Added. Please try again.'
        },
        () => {
          setTimeout(() => {
            this.reset();
          }, 3000);
        }
      );
    } else {
      this.setState(
        {
          alert: false,
          color: 'green',
          message: 'Organization Added'
        },
        () => {
          setTimeout(() => {
            this.reset();
            callback();
          }, 1500);
        }
      );
    }
  };

  render() {
    const {
      organizationName,
      firstName,
      lastName,
      email,
      color,
      alert,
      message,
      adminSetsPassword,
      password
    } = this.state;

    const variables = {
      organizationName: organizationName,
      firstName: firstName,
      lastName: lastName,
      email: email.toLowerCase()
    };

    let passwordInput;
    let mutation = addOrganizationMutation;

    if (adminSetsPassword) {
      passwordInput = (
        <Form.Input
          label="Password"
          name="password"
          value={password}
          type="password"
          placeholder="Password"
          onChange={this.handleInputChange}
          required
        />
      );

      mutation = addOrgManuallyMutation;
      //set the manual password
      variables.password = password;
    }

    return (
      <React.Fragment>
        <Message color={color} hidden={alert} content={message} />
        <Mutation
          mutation={mutation}
          variables={variables}
          refetchQueries={() => [{ query: this.props.query }]}
          onCompleted={data => this.sent(data)}
        >
          {addOrganizations => (
            <Form
              onSubmit={e => {
                e.preventDefault();
                addOrganizations();
              }}
            >
              <Form.Group>
                <Form.Field>
                  <Radio
                    label="User sets password (recommended)"
                    name="radioGroup"
                    value="false"
                    checked={!adminSetsPassword}
                    onChange={this.handleRadioChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Admin sets password"
                    name="radioGroup"
                    value="true"
                    checked={adminSetsPassword}
                    onChange={this.handleRadioChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label="Organization Name"
                  name="organizationName"
                  value={organizationName}
                  type="text"
                  placeholder="Organization Name"
                  onChange={this.handleInputChange}
                  required
                />
                <Form.Input
                  label="Contact Email"
                  name="email"
                  value={email}
                  type="email"
                  placeholder="Organization Contact Email"
                  onChange={this.handleInputChange}
                  required
                />
                {passwordInput}
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label="First Name"
                  name="firstName"
                  value={firstName}
                  type="text"
                  placeholder="First Name"
                  onChange={this.handleInputChange}
                  required
                />
                <Form.Input
                  label="Last Name"
                  name="lastName"
                  value={lastName}
                  type="text"
                  placeholder="Last Name"
                  onChange={this.handleInputChange}
                  required
                />
              </Form.Group>
              <Button type="submit">Add Organization</Button>
            </Form>
          )}
        </Mutation>
      </React.Fragment>
    );
  }
}

export default AddOrganizationForm;
