import React, { Component } from 'react';
// import { Button } from 'reactstrap';
import { Dropdown } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import {
  enableOrgMutation,
  disableOrgMutation,
} from './graphql/mutations/secMutations';

class EnableDisableOrgs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: props.currentState,
    };
  }

  sent = (data) => {
    this.setState({ isActive: !this.state.isActive });
  };

  render() {
    const { id, query } = this.props;
    const { isActive } = this.state;
    return isActive ? (
      <Mutation
        mutation={disableOrgMutation}
        variables={{ torg: id }}
        refetchQueries={() => [{ query: query }]}
        onCompleted={(data) => this.sent(data)}
      >
        {(disableOrg) => (
          <Dropdown.Item
            onClick={disableOrg}
            icon="x"
            text="Disable Organization"
          />
        )}
      </Mutation>
    ) : (
      <Mutation
        mutation={enableOrgMutation}
        variables={{ torg: id }}
        refetchQueries={() => [{ query: query }]}
        onCompleted={(data) => this.sent(data)}
      >
        {(enableOrg) => (
          <Dropdown.Item
            icon="exclamation"
            text="Enable Organization"
            onClick={enableOrg}
          />
        )}
      </Mutation>
    );
  }
}
export default EnableDisableOrgs;
