import React, { Component } from 'react';
// import { Row, Col, Container, Alert } from 'reactstrap';
import { Grid, Message, Container, Icon, Loader } from 'semantic-ui-react';
import { Query } from 'react-apollo';
import {
  administratorsQuery,
  orgAdminsQuery,
  authenticatedUsersQuery,
} from './graphql/queries/getQueries';
import Organizations from './Organizations';
import Users from './Users';
import Header from './Header';
import withAuth from '../Auth/withAuth';
import AuthService from '../Auth/AuthService';

const Auth = new AuthService();

class Home extends Component {
  constructor(props) {
    super(props);

    const role = props.user.role;
    let qry;

    switch (role) {
      case 'app_authenticated':
        qry = authenticatedUsersQuery;
        break;
      case 'app_administrator':
        qry = administratorsQuery;
        break;
      case 'app_org_admin':
        qry = orgAdminsQuery;
        break;
      default:
        qry = null;
        break;
    }

    this.state = {
      query: qry,
    };
  }

  logOut() {
    Auth.logout();
    this.props.history.replace('/login');
  }

  render() {
    const { role, org_id, user_id } = this.props.user;
    const { query } = this.state;

    return (
      <Query query={query} fetch-policy="cache-and-network">
        {({ loading, error, data, refetch }) => {
          if (loading)
            return (
              <Loader size="large" indeterminate active>
                Loading Data
              </Loader>
            );
          if (error)
            return (
              <Message color="red">
                <Icon name="x" />
                <Message.Content>Error {error}</Message.Content>
              </Message>
            );

          let users = data.users.user;
          let orgs = data.orgs.org;
          return (
            <React.Fragment>
              <Header history={this.props.history} />
              <Container fluid className="content-container">
                <Grid width={16}>
                  <Grid.Row centered stretched>
                    {role !== 'app_authenticated' && (
                      <Grid.Column width={6}>
                        <Organizations
                          role={role}
                          user={user_id}
                          org={org_id}
                          orgs={orgs}
                          query={query}
                        />
                      </Grid.Column>
                    )}
                    <Grid.Column width={6}>
                      <Users
                        role={role}
                        user={user_id}
                        org={org_id}
                        users={users}
                        query={query}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
export default withAuth(Home);
