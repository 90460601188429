import React, { Component } from 'react';
import { Table, Dropdown } from 'semantic-ui-react';
import AddOrganizationButton from './AddOrganizationButton';
import EnableDisableOrgs from './EnableDisableOrgs';
import AddUserMenuItem from './AddUserMenuItem';

class Organizations extends Component {
  constructor(props) {
    super(props);

    const role = props.role;
    let showOrgActions, showAddOrgs, showAddUsers;

    switch (role) {
      case 'app_authenticated':
        showOrgActions = false;
        showAddUsers = false;
        break;
      case 'app_administrator':
        showAddOrgs = true;
        showAddUsers = true;
        showOrgActions = true;
        break;
      case 'app_org_admin':
        showOrgActions = false;
        showAddUsers = true;
        break;
      default:
        showOrgActions = false;
        showAddUsers = false;
        break;
    }

    this.state = {
      showOrgActions: showOrgActions,
      showAddOrgs: showAddOrgs,
      showAddUsers: showAddUsers,
      addOrgsForm: false,
    };
  }

  addOrgsForm = () => {
    this.setState((prevState) => ({
      addOrgs: !prevState.addOrgs,
    }));
  };

  addUsersForm = () => {
    this.setState((prevState) => ({
      addUsers: !prevState.addUsers,
    }));
  };

  render() {
    const { orgs, query } = this.props;
    const { showAddOrgs, showAddUsers, showOrgActions } = this.state;

    const tableRows = orgs.map((org) => {
      let orgDisabled = false;
      if (org && !org.isActive) {
        orgDisabled = true;
      }

      return (
        <Table.Row key={org.email}>
          <Table.Cell disabled={orgDisabled} colSpan="2">
            {org.email && (
              <a href={'mailto:' + org.email}>{org.organizationName}</a>
            )}
          </Table.Cell>
          {/* <Table.Cell disabled={orgDisabled}>
            {org.activeUsers && (
              <Label>{org.activeUsers + '/' + org.maxActiveUsers}</Label>
            )}
          </Table.Cell> */}
          <Table.Cell>
            {(showOrgActions || showAddUsers) && (
              <Dropdown item icon="ellipsis horizontal">
                <Dropdown.Menu>
                  {showAddUsers && (
                    <AddUserMenuItem
                      orgId={org.id}
                      icon="add user"
                      text="Add User"
                      query={query}
                    />
                  )}
                  {showOrgActions && (
                    <EnableDisableOrgs
                      id={org.id}
                      currentState={org.isActive}
                      query={query}
                    />
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">ORGANIZATIONS</Table.HeaderCell>
            <Table.HeaderCell>
              {showAddOrgs && <AddOrganizationButton query={query} />}
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell colSpan="2">Org. Name</Table.HeaderCell>
            {/* <Table.HeaderCell>Active Users/Total</Table.HeaderCell> */}
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{tableRows}</Table.Body>
      </Table>
    );
  }
}
export default Organizations;
