import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { newPasswordMutation } from './graphql/mutations/secMutations';

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: 'green',
      active: false
    };
  }
  sent = data => {
    if (!data.newPassword.passed) {
      this.setState({ color: 'red', active: false });
    } else {
      this.setState({ color: 'blue', active: true });
    }
  };
  render() {
    return (
      <Mutation
        mutation={newPasswordMutation}
        variables={{ requestUser: this.props.id }}
        onCompleted={data => this.sent(data)}
      >
        {newPassword => (
          <Dropdown.Item
            icon="unlock"
            text="Reset Password"
            onClick={newPassword}
          />
        )}
      </Mutation>
    );
  }
}
export default NewPassword;
