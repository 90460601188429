import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import './index.css';
import App from './App';
import 'semantic-ui-css/semantic.min.css';
import * as serviceWorker from './serviceWorker';
import './index.css';

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_SERVER,
  fetchOptions: {
    credentials: 'include',
  },
  request: (operation) => {
    const token = localStorage.getItem('landpoint-token');
    if (token) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  },
  onError: (errorObj) => {
    const networkErr = errorObj.networkError;
    const networkStatus = networkErr ? networkErr.statusCode : null;
    switch (networkStatus) {
      case 403:
        localStorage.removeItem('landpoint-token');
        window.location.reload();
        break;
      default:
        break;
    }
  },
});

ReactDOM.render(
  <Router basename={process.env.PUBLIC_URL}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
