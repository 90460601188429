import React, { Component } from 'react';
// import { Nav, Navbar, NavbarBrand, NavItem, NavLink, Button } from 'reactstrap';
import { Menu, Icon } from 'semantic-ui-react';
import AuthService from '../Auth/AuthService';

const Auth = new AuthService();

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  logOut() {
    Auth.logout();
    this.props.history.replace('/login');
  }

  render() {
    return (
      <Menu attached="top" inverted>
        <Menu.Item header href="/">
          <h3>Landpoint Admin</h3>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item onClick={this.logOut.bind(this)}>
            <Icon name="log out" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
}
export default Header;
