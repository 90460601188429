import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
// import {
//   Card,
//   CardBody,
//   CardHeader,
//   CardTitle,
//   Form,
//   FormGroup,
//   Row,
//   Col,
//   Label,
//   Input,
//   Button,
//   Alert
// } from 'reactstrap';
import {
  Grid,
  Card,
  Message,
  Form,
  Button,
  Image,
  Header,
} from 'semantic-ui-react';
import { recoverMutation } from './graphql/mutations/recoverPassword';

export default class FPassword extends Component {
  constructor(props) {
    super();
    this.state = {
      email: '',
      alert: false,
      color: null,
      message: '',
    };
  }

  recovery = (data) => {
    if (!data.recoverPassword.passed) {
      this.setState({
        alert: true,
        color: 'negative',
        message: 'Sorry, we can not send an email at this time',
      });
    } else {
      this.setState({
        alert: true,
        color: 'positive',
        message:
          'Please check your email and follow the instructions for a new password',
      });
      setTimeout(() => {
        window.location.replace(
          'https://landpoint.spsgis.com/login/index.html'
        );
      }, 5000);
    }
  };

  render() {
    const { email } = this.state;
    return (
      <Grid container relaxed width={16}>
        <Grid.Row stretched centered>
          <Grid.Column width={6} stretched verticalAlign={'middle'}>
            <Image
              centered
              small
              src="https://sps-client-logos.s3.amazonaws.com/SPS-logo_tagline_transparent.png"
            />
            <Header as="h2" color="grey">
              Welcome to LandPoint by Single Point Solutions
            </Header>
            <p className="subtitle">Please enter your email below.</p>
            <Card fluid>
              <Card.Content>
                <Form>
                  {this.state.alert && (
                    <Message
                      visible={this.state.alert}
                      color={this.state.color}
                      content={this.state.message}
                    />
                  )}
                  <Form.Group>
                    <Form.Input
                      type="email"
                      name="name"
                      width={16}
                      icon="envelope"
                      iconPosition="left"
                      value={email}
                      placeholder="E-mail address"
                      onChange={(e) => this.setState({ email: e.target.value })}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Mutation
                      mutation={recoverMutation}
                      variables={this.state}
                      onCompleted={(data) => this.recovery(data)}
                    >
                      {(doRecover) => (
                        <Button color="grey" fluid onClick={doRecover}>
                          Recover Password
                        </Button>
                      )}
                    </Mutation>
                  </Form.Group>
                </Form>
              </Card.Content>
            </Card>
            <Link to="/login">Return to Login</Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
