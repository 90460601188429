import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import {
  addUserMutation,
  addUserManuallyMutation
} from './graphql/mutations/secMutations';
import { Button, Form, Message, Radio } from 'semantic-ui-react';

class AddUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      addUser: false,
      alert: true,
      color: 'green',
      message: '',
      adminSetsPassword: false,
      password: ''
    };
  }

  reset = () => {
    this.setState({
      email: '',
      firstName: '',
      lastName: '',
      addUser: false,
      alert: true,
      color: 'green',
      message: '',
      adminSetsPassword: false,
      password: ''
    });
  };

  handleInputChange = (e, { name, value }) => {
    this.setState({
      [name]: value
    });
  };

  handleRadioChange = (e, { value }) => {
    if (value === 'true') {
      this.setState({
        adminSetsPassword: true
      });
    } else {
      this.setState({
        adminSetsPassword: false
      });
    }
  };

  sent = data => {
    const { callback } = this.props;
    const { adminSetsPassword } = this.state;
    let user;
    if (adminSetsPassword) {
      user = data.addUserManually.newUser;
    } else {
      user = data.addUser.user;
    }

    if (user == null) {
      this.setState(
        {
          alert: false,
          color: 'red',
          message: 'User Could Not Be Added, Email Already In Use'
        },
        () => {
          setTimeout(() => {
            this.reset();
          }, 1500);
        }
      );
    } else {
      this.setState(
        { alert: false, color: 'green', message: 'User Added' },
        () => {
          setTimeout(() => {
            this.reset();
            callback();
          }, 1500);
        }
      );
    }
  };

  render() {
    const { orgId, query } = this.props;

    const {
      email,
      firstName,
      lastName,
      alert,
      color,
      message,
      adminSetsPassword,
      password
    } = this.state;
    const variables = {
      organizationId: orgId,
      firstName: firstName,
      lastName: lastName,
      email: email.toLowerCase()
    };
    // variables.organizationId = orgId;

    let passwordInput;
    let mutation = addUserMutation;

    if (adminSetsPassword) {
      passwordInput = (
        <Form.Input
          label="Password"
          name="password"
          value={password}
          type="password"
          placeholder="Password"
          onChange={this.handleInputChange}
          required
        />
      );

      mutation = addUserManuallyMutation;

      //set the manual password
      variables.password = password;
    }

    return (
      <React.Fragment>
        <Message color={color} hidden={alert} content={message} />
        <Form>
          <Form.Group>
            <Form.Field>
              <Radio
                label="User sets password (recommended)"
                name="radioGroup"
                value="false"
                checked={!adminSetsPassword}
                onChange={this.handleRadioChange}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label="Admin sets password"
                name="radioGroup"
                value="true"
                checked={adminSetsPassword}
                onChange={this.handleRadioChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Email"
              name="email"
              value={email}
              type="email"
              placeholder="Email"
              onChange={this.handleInputChange}
              required
            />
            {passwordInput}
            <Form.Input
              label="First Name"
              name="firstName"
              value={firstName}
              type="text"
              placeholder="First Name"
              onChange={this.handleInputChange}
              required
            />
            <Form.Input
              label="Last Name"
              name="lastName"
              value={lastName}
              type="text"
              placeholder="Last Name"
              onChange={this.handleInputChange}
              required
            />
          </Form.Group>
          <Mutation
            mutation={mutation}
            variables={variables}
            onCompleted={data => this.sent(data)}
            refetchQueries={() => [{ query: query }]}
          >
            {addUser => <Button onClick={addUser}>Add User</Button>}
          </Mutation>
        </Form>
      </React.Fragment>
    );
  }
}

export default AddUserForm;
