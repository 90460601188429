import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import {
  escalateOrgAdmin,
  deescalateOrgAdmin
} from './graphql/mutations/secMutations';

class EscalateOrgAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgQuery: null,
      isOrgAdmin: this.props.currentState
    };
  }

  sent = data => {
    this.setState({ isOrgAdmin: !this.state.isOrgAdmin });
  };

  componentWillMount() {
    this.setState({ orgQuery: this.props.orgquery });
  }

  render() {
    const { isOrgAdmin } = this.state;

    return isOrgAdmin === true ? (
      <Mutation
        mutation={deescalateOrgAdmin}
        variables={{ tuserId: this.props.id }}
        refetchQueries={() => [{ query: this.props.query }]}
        onCompleted={data => this.sent(data)}
      >
        {disableUser => (
          <Dropdown.Item
            icon="user circle outline"
            text="Downgrade Org Admin"
            onClick={disableUser}
          />
        )}
      </Mutation>
    ) : (
      <Mutation
        mutation={escalateOrgAdmin}
        variables={{ tuserId: this.props.id }}
        refetchQueries={() => [{ query: this.props.query }]}
        onCompleted={data => this.sent(data)}
      >
        {enableUser => (
          <Dropdown.Item
            icon="user circle"
            text="Make Org Admin"
            onClick={enableUser}
          />
        )}
      </Mutation>
    );
  }
}
export default EscalateOrgAdmin;
