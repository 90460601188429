import gql from 'graphql-tag';

const recoverMutation = gql`
  mutation recoverPassword($email: String!) {
    recoverPassword(input: { requestEmail: $email }) {
      passed: boolean
    }
  }
`;

const changePasswordMutation = gql`
  mutation changePassword(
    $tpuuid: UUID!
    $tquuid: UUID!
    $newPassword: String!
  ) {
    changePassword(
      input: { tpuuid: $tpuuid, tquuid: $tquuid, newPassword: $newPassword }
    ) {
      changed: boolean
    }
  }
`;

export { recoverMutation, changePasswordMutation };
